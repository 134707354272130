let BACKEND_SERVER = null;
let BACKEND_SERVER_URL = null;
let KEY_GOOGLE_MAPS = null;
if (process.env.REACT_APP_BACKEND_SERVER) {
  BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
} else {
    //BACKEND_SERVER = "https://localhost:7060/api/";
    //BACKEND_SERVER = "https://api-eoliengo-dev.natasquad.com/api/";
    BACKEND_SERVER = "https://api-eoliengo.natasquad.com/api/";
    BACKEND_SERVER_URL = "https://api-eoliengo.natasquad.com/";
    KEY_GOOGLE_MAPS = "AIzaSyBsegSGou7a0RuT44L0xGBVS1ZAe8KRHSg";
}

const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/projects/list',
    fontFamily: `'OpenSans', sans-serif`,
    borderRadius: 12,
    API_SERVER: BACKEND_SERVER,
    URL_SERVER: BACKEND_SERVER_URL,
    KEY_GOOGLE_MAPS: KEY_GOOGLE_MAPS
};

export default config;
