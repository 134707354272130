import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';


const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
const Projects = Loadable(lazy(() => import('../views/projects')));
const ProjectForm = Loadable(lazy(() => import('../views/projects/form')));
const ProjectDetails = Loadable(lazy(() => import('../views/projects/details')));
const ProjectDetailsVersion = Loadable(lazy(() => import('../views/projects/detailsVersion')));
const Utilisateurs = Loadable(lazy(() => import('../views/utilisateurs')));
const UtilisateurForm = Loadable(lazy(() => import('../views/utilisateurs/form')));
const UtilisateurDetails = Loadable(lazy(() => import('../views/utilisateurs/details')));
const Contacts = Loadable(lazy(() => import('../views/contacts')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard/default',
                '/projects/list',
                '/projects/form',
                '/projects/details',                
                '/projects/detailsVersion',
                '/utilisateurs/list',
                '/utilisateurs/form/',
                '/utilisateurs/details',                
                '/contacts'
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path="/dashboard/default" component={DashboardDefault} />
                        <Route path="/projects/list" component={Projects} />
                        <Route path="/projects/form" component={ProjectForm} />
                        <Route path="/projects/details" component={ProjectDetails} />
                        <Route path="/projects/detailsVersion" component={ProjectDetailsVersion} />
                        <Route path="/utilisateurs/list" component={Utilisateurs} />
                        <Route path="/utilisateurs/form" component={UtilisateurForm} />
                        <Route path="/utilisateurs/details" component={UtilisateurDetails} />
                        <Route path="/contacts" component={Contacts} />

                        <Route path="/utils/util-typography" component={UtilsTypography} />
                        <Route path="/utils/util-color" component={UtilsColor} />
                        <Route path="/utils/util-shadow" component={UtilsShadow} />
                        <Route path="/icons/tabler-icons" component={UtilsTablerIcons} />
                        <Route path="/icons/material-icons" component={UtilsMaterialIcons} />
                        <Route path="/sample-page" component={SamplePage} />
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
