// assets
import { IconChartAreaLine, IconDashboard, IconChartBar, IconUsers, IconMail, IconBrandCodesandbox } from '@tabler/icons';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconChartBar: IconChartBar,
    IconUsers: IconUsers,
    IconMail: IconMail,
    IconBrandCodesandbox: IconBrandCodesandbox,
    IconChartAreaLine: IconChartAreaLine
};
/*const account = useSelector((state) => state.account);*/

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const main = {
    id: 'main',
    title: '',
    type: 'group',
    children: [
        {
            id: 'projects',
            title: 'Projets',
            type: 'item',
            url: '/projects/list',
            //icon: icons['IconBrandCodesandbox'],
            breadcrumbs: false
        },
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            //icon: icons['IconChartAreaLine'],
            breadcrumbs: false
        },            
        {
            id: 'utilisateurs',
            title: 'Utilisateurs',
            type: 'item',
            url: '/utilisateurs/list',
            //icon: icons['IconUsers'],
            breadcrumbs: false
        },
        {
            id: 'contact',
            title: 'Contact',
            type: 'item',
            url: '/contacts',
            //icon: icons['IconMail'],
            breadcrumbs: false
        }
    ]
};
